<template>
  <div>
    <b-modal
      id="modal-pdf-viewer"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      title=""
      ref="modalPdfViewer"
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer></pdf-viewer>
    </b-modal>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Toon</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>items</label>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Zoeken..."
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        :items="fetchPayslips"
        :fields="tableColumns"
        striped
        responsive
        show-empty
        empty-text="Geen overeenkomende records gevonden"
        class="position-relative"
      >
        <template #cell(netPay)="data">
          &euro; {{ data.value }}
        </template>
        <template #cell(paidAmount)="data">
          &euro; {{ data.value }}
        </template>
        <template #cell(timestamp)="data">
          {{ formatDateTime(data.value) }}
        </template>
        <template #cell(period)="data">
          {{ formatYearMothString(data.value) }}
        </template>
        <template #cell(netPay)="data">
          &euro; {{ data.value?parseFloat(data.value).toFixed(2):'0.00' }}
        </template>
        <template #cell(paidAmount)="data">
          &euro; {{ data.value?parseFloat(data.value).toFixed(2):'0.00' }}
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`pdf-row-${data.item.id}-preview-icon`"
              class="cursor-pointer mx-1"
              icon="EyeIcon"
              size="16"
              style="color: #29625b;"
              @click="viewDocument(data.item)"
            />
            <b-link
              :href="`${axiosDefaultConfig.backendEndPoint}paychecks/${data.item.id}/pdf?token=${data.item.pdfToken}`"
              target="_blank"
            >
              <feather-icon
                :id="`payslips-row-${data.item.id}-download-icon`"
                class="cursor-pointer mx-1"
                icon="DownloadIcon"
                size="16"
              />
<!--              <b-tooltip-->
<!--                :target="`payslips-row-${data.item.id}-download-icon`"-->
<!--                placement="top"-->
<!--              >-->
<!--                <p class="mb-0">-->
<!--                  Downloaden-->
<!--                </p>-->
<!--              </b-tooltip>-->
            </b-link>
          </div>
        </template>
        <template #cell(show_details)="row">

          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Verbergen' : 'Tonen' }}
          </b-form-checkbox>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <b-card
            title="Uitbetalingen"
          >
            <b-row class="mb-2">
              <b-table
                :fields="tablePayoutsColumns"
                :items="row.item.payments"
              >
                <template #cell(paycheckID)="data">
                  #{{ data.item.id }}
                </template>
                <template #cell(paymentTimestamp)="data">
                  {{ formatDateTime(data.value) }}
                </template>
                <template #cell(amount)="data">
                  &euro; {{ data.value?parseFloat(data.value).toFixed(2):'0.00' }}
                </template>
                <template #cell(status)="data">
                  <b-badge :variant="resolvePayOutStatusVariant(data.value).variant">
                    {{ resolvePayOutStatusVariant(data.value).status }}
                  </b-badge>
                </template>
              </b-table>
            </b-row>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Verberg details
            </b-button>
          </b-card>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
                dataMeta.of
              }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BLink,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { formatDateTime, formatYearMothString } from '@core/utils/utils'
import usePaySlipsList from '@/views/pages/pay-slips/usePaySlipsList'
import paySlipsStoreModule from '@/views/pages/pay-slips/paySlipsStoreModule'
import vSelect from 'vue-select'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import PdfViewer from '@/views/components/pdf-viewer/PdfViewer.vue'

export default {
  components: {
    BCard,
    BRow,
    BTable,
    BCol,
    BFormCheckbox,
    vSelect,
    BPagination,
    BButton,
    BFormInput,
    PdfViewer,
    BLink,
    BBadge,
  },
  data() {
    return {
      fetchPaySlips: [],
      fetchPayouts: [],
    }
  },
  methods: {
    viewDocument(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}paychecks/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
  },
  setup() {
    const PAYSLIPS_PAYOUT_APP_STORE_MODULE_NAME = 'app-payslips'

    // Register module
    if (!store.hasModule(PAYSLIPS_PAYOUT_APP_STORE_MODULE_NAME)) store.registerModule(PAYSLIPS_PAYOUT_APP_STORE_MODULE_NAME, paySlipsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYSLIPS_PAYOUT_APP_STORE_MODULE_NAME)) store.unregisterModule(PAYSLIPS_PAYOUT_APP_STORE_MODULE_NAME)
    })

    const {
      refPaySlipsListTable,
      dataMeta,
      tableColumns,
      perPage,
      total,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showOverlay,
      searchQuery,
      tablePayoutsColumns,
      refetchData,
      fetchPayslips,
      resolvePayOutStatusVariant,
    } = usePaySlipsList()

    return {
      refPaySlipsListTable,
      dataMeta,
      tableColumns,
      perPage,
      total,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      showOverlay,
      searchQuery,
      tablePayoutsColumns,
      refetchData,
      fetchPayslips,
      axiosDefaultConfig,
      formatYearMothString,
      formatDateTime,
      resolvePayOutStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
